import * as React from "react";

import CaseStudyPage from "../components/case_study/caseStudy";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const Client = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title="Clients"
        description={t('find_out_how_we_ve_helped_our_customers')}
      />
      <Layout>
        <CaseStudyPage />
      </Layout>
    </>
  );
};

export default Client;
