import React, { useState,useEffect } from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Subscribe from "../subscribe/subscribe_2";
import TopBanner from "../top_banner/top_banner";
import Video from "../video/videoContainer";
import VideoModal from "../video/videoModal";
import { useTranslation } from 'react-i18next';

const CaseStudy = () => {
  const {t} = useTranslation();
  const breakpoints = useBreakpoint();
  const navHeader = [
    {
      title: `${t('professional_recruitment')}`,
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scope_on_note_icon.svg",
      imgSrcHovered: "https://d2lxkizvrhu4im.cloudfront.net/icons/scope_on_note_yellow_icon.svg",
      id: "1",
    },
    {
      title: `${t('higher_education')}`,
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scholar_cap_icon.svg",
      imgSrcHovered: "https://d2lxkizvrhu4im.cloudfront.net/icons/scholar_cap_yellow_icon.svg",
      id: "2",
    },
    {
      title: "Certification",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/certificate_icon.svg",
      imgSrcHovered: "https://d2lxkizvrhu4im.cloudfront.net/icons/certificate_yellow_icon.svg",
      id: "3",
    },
    {
      title: `${t('professional_training')}`,
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/presentation_icon.svg",
      imgSrcHovered: "https://d2lxkizvrhu4im.cloudfront.net/icons/presentation_yellow_icon.svg",
      id: "4",
    },
  ];

  const details = [
    {
      id: "1",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/case_study_image_1.png",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scope_on_note_yellow_with_bg_icon.svg",
      title: `${t('professional_recruitment')}`,
      titleIconSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/current.svg",
      subTitle: `${t('cours_legendre')}`,
      subTitleBold: `${t('recruits_its_speakers')}`,
      description:
        `${t('to_guarantee_quality_teaching')}`,
      review:
        `${t('mereos_gave_us_the_flexibility')}`,
      reviewr: "Sabine Romeu",
      reviewerDesignation: `${t('director_of_human_resources')}`,
    },
    {
      id: "2",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/case_study_image_2.png",
      videoSrc: "/videos/clientsSectionVideo.mp4",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scholar_cap_yellow_with_bg_icon.svg",
      title: `${t('higher_education')}`,
      titleIconSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/current.svg",
      subTitle: `${t('esam_paris')}`,
      subTitleBold: `${t('recruits_its_students')}`,
      description:
        `${t('to_preserve_its_academic')}`,
      review:
        `${t('in_three_years_we')}`,
      reviewr: "Yannick Roussel",
      reviewerDesignation: `${t('general_manager')}`,
    },
    {
      id: "3",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/case_study_image_3.png",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/certificate_yellow_with_bg_icon.svg",
      title: "  Certification",
      titleIconSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/current.svg",
      subTitle: "HCFDC",
      subTitleBold: `${t('administers_its_certifications')}`,
      description:
        `${t('digitize_its_certifications')}`,
      review:
        `${t('mereos_gave_us_the_flexibility_and_autonomy')}`,
      reviewr: "Chrisitian Sommade",
      reviewerDesignation: `${t('executive_officer')}`,
    },
    {
      id: "4",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/case_study_image_4.png",
      videoSrc: "/videos/sample_video.mp4",
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/presentation_yellowwith_bg_icon.svg",
      title: `${t('professional_training')}`,
      titleIconSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/current.svg",
      subTitle: `${t('TonAvenir')}`,
      subTitleBold: `${t('trains_its_franchisees')}`,
      description:
        `${t('the_tonAvenir_orientation')}`,
      review:
        `${t('we_have_been_working')}`,
      reviewr: "Sophie Laborde-Balen",
      reviewerDesignation: `${t('executive_director')}`,
    },
  ];

  const videoId="637100295"
  const [isHover, setHover] = useState(0);
  const [isActive, setActive] = useState(0);
  const [sliderActive, setSliderActive] = useState(0);
  const [isOpen, setOpen]=useState(false)


  

  const leftClickHandler = () => {
    if (sliderActive !== 0) {
      setSliderActive(sliderActive - 1);
    }
  };

  const rightClickHandler = () => {
    if (sliderActive < navHeader.length - 1) {
      setSliderActive(sliderActive + 1);
    }
  };

  const handleOpen = (i) => {
    if (i === 1) {
      setOpen(true)
    }
  }

  return (
    <>
      <div className={`conatiner font-sans`}>
        <VideoModal
          isOpen={isOpen}
          setOpen={setOpen}
          videoSrcURL={details[1].videoSrc}
        />
        <TopBanner
          pageTitle="Clients"
          title={t('why_did_our_customers_choose_mereos')}
          description={t('from_educational_establishments')}
          imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/step_to_succes.svg"
          noReverse
          clients
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 md:w-10/12`}>
          <div className="flex flex-row justify-between w-full mx-auto border-t-2 border-b-2 border-gray-200 px-12 pt-7 pb-7 my-20">
            {
              navHeader.map((i, index) => {
                return (
                  <a href={`#${i.id}`} className="hidden md:flex w-60">
                    <div
                      onMouseEnter={() => setHover(index + 1)}
                      onMouseOut={() => setHover(0)}
                      onClick={() => setActive(i.id)}
                      className={`flex flex-col items-center w-1/2 text-center hover:text-yellow-500 hover:font-bold z-10 ${
                        isActive === i.id ? "text-yellow-500" : ""
                      }`}
                    >
                      <img
                        src={
                          isHover === index + 1 || isActive === i.id
                            ? i.imgSrcHovered
                            : i.imgSrc
                        }
                        className="mx-auto z-0"
                        alt="note with scope"
                      />

                      {i.title}
                    </div>
                  </a>
                );
              })
            }

            <div className="flex md:hidden flex-row justify-around items-center relative z-10 w-full">
              <div
                onClick={leftClickHandler}
                className="flex flex-col items-center justify-center"
              >
                <img
                  alt="icons for arrow"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-right-yellow-icon.svg"
                  className="transform rotate-180 m-0"
                />
              </div>

              <div className={`w-1/2 mx-auto text-center text-yellow-500 text-base flex flex-col items-center font-semibold`}>
                {navHeader[sliderActive].title}
              </div>

              <div
                onClick={rightClickHandler}
                className="flex flex-col items-center"
              >
                <img
                  alt="icons for arrow"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-right-yellow-icon.svg"
                  className="transform m-0"
                />
              </div>
            </div>

            {/* <div className="hidden md:flex flex-col items-center justify-center cursor-pointer hover:text-yellow-500">
              <span>E-commerce</span> <span>and Retail</span>
            </div>
            <div className="hidden md:flex flex-col items-center justify-center cursor-pointer hover:text-yellow-500">
              <span>E-commerce</span> <span>and Retail</span>
            </div>
            <div className="hidden md:flex flex-col items-center justify-center cursor-pointer hover:text-yellow-500">
              <span>E-commerce</span> <span>and Retail</span>
            </div>
            <div className="hidden md:flex flex-col items-center justify-center cursor-pointer hover:text-yellow-500">
              <span>E-commerce</span> <span>and Retail</span>
            </div> */}
          </div>
          {/* Get_Started_section */}

          <div className="my-20">
            {
              details.map((i, index) => {
                return (
                  <div
                    className={` my-24 ${
                      !breakpoints.md
                        ? ""
                        : index === sliderActive
                        ? "block"
                        : "hidden"
                    }`}
                    id={i.id}
                  >
                    <div className="hidden md:flex justify-center items-center w-full mx-auto">
                      {/* <img
                      alt="right star "
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/right_star_icon.svg"
                      className="hidden md:block"
                    /> */}
                      <img
                        alt="rocket icon"
                        src={i.iconSrc}
                        // style={{ marginTop: index === 2 ? 0 : 0 }}
                      />
                      {/* <img
                      alt="left star"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
                      className="hidden md:block"
                    /> */}
                    </div>
                    <div className="hidden md:block text-5xl text-center font-bold ">
                      {" "}
                      {i.title}
                    </div>
                    <div className="font-sans flex flex-col md:my-16  mx-auto w-full lg:w-11/12">
                      <div
                        className={`flex flex-col-reverse  justify-between w-full  ${
                          index % 2 == 0 ? "md:flex-row" : "md:flex-row-reverse"
                        }`}
                      >
                        <div
                          className={`w-full md:w-1/2 flex flex-col ${
                            index % 2 == 0 ? "items-start" : "items-end"
                          }`}
                        >
                          <div className="flex flex-col md:w-11/12 ">
                            <div>
                              <button className="border-2 border-gray-100 rounded-3xl p-2 ">
                                <img
                                  src={i.titleIconSrc}
                                  className="my-0"
                                  alt="current"
                                />
                              </button>
                            </div>
                            <div className="text-2xl md:text-3xl   text-left my-4">
                              {i.subTitle}&nbsp;
                              <span className="font-extrabold" dangerouslySetInnerHTML={{__html:i.subTitleBold}}>
                                {/* {i.subTitleBold} */}
                              </span>
                            </div>
                            <div className="text-gray-500  text-left  border-b-2 border-gray-100 pb-5 w-5/6 ">
                              {i.description}
                            </div>
                            <div className="list-none  font-extrabold w-5/6 mt-5 relative">
                              <div className="relative z-10  ">{i.review}</div>

                              <div className="absolute w-10 top-0 -left-5 z-0">
                                {" "}
                                <img
                                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/Quotes_icon.svg"
                                  alt="quotes"
                                />{" "}
                              </div>
                            </div>
                            <div className="text-gray-400 text-500 flex flex-col my-3">
                              <span>{i.reviewr}</span>
                              <span>{i.reviewerDesignation}</span>
                            </div>
                            <div className=" ">
                              <Link to="/demo" className="bg-yellow-300 px-6 py-3 font-bold rounded-sm text-lg w-full md:w-1/2 lg:w-1/2 ">
                                {t('learn_more')}
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 mx-auto relative ">
                          <img
                            src="https://d2lxkizvrhu4im.cloudfront.net/images/background_dotted_yellow_2.svg"
                            alt="background logo"
                            className={`absolute  -bottom-14 -left-10 z-0 ${
                              false ? "hidden" : "block"
                            }`}
                          />
                          <img
                            src="https://d2lxkizvrhu4im.cloudfront.net/images/background_random_jelly_design.svg"
                            alt="backgound image"
                            className={`absolute -bottom-10 md:-bottom-28 w-10/12 md:h-600 md:w-500 z-0 ${
                              false? "hidden" : "block"
                            }`}
                          />

                          <img
                            alt="get started"
                            src={i.imgSrc}
                            className=" z-50  w-11/12 absolute"
                            data-video={`${i.videoSrc}`}
                            onClick={() => handleOpen(index)}
                          />

                          {/* {index !== 1 ? (
                            <img
                              alt="get started"
                              src={i.imgSrc}
                              className="w-full"
                            />
                          ) : (
                            <Video
                              videoSrcURL={i.videoSrc}
                              small
                              className="z-50 w-10/12 md:w-500 md:h-500 absolute"
                            />
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>

        {/* Bottom Banner */}
        <Subscribe />
      </div>
    </>
  );
};

export default CaseStudy;
